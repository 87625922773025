@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gradient-to-r from-black via-gray-900 to-black text-gray-100;
  overflow-x: hidden;
}

.section {
  @apply py-16 px-4 md:px-8;
}

.profile-img {
  @apply w-36 h-36 rounded-full mx-auto;
}

.floating {
  @apply fixed w-24 h-24 bg-white bg-opacity-10 rounded-full;
}

.floating-1 {
  top: 10%;
  left: 15%;
  animation: float 8s ease-in-out infinite;
}

.floating-2 {
  top: 20%;
  right: 20%;
  animation: float 6s ease-in-out infinite;
}

.floating-3 {
  bottom: 10%;
  left: 40%;
  animation: float 10s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.section-content p {
  text-align: justify;
}

.contact-icons a {
  @apply text-gray-300 text-2xl mx-2;
}

.project-card {
  @apply bg-gray-900 rounded-lg p-5 my-5 text-left shadow-lg transition-transform transform hover:scale-105;
}

.project-card h3 {
  @apply mt-0;
}

.project-card img {
  @apply w-full rounded-t-lg;
}

.project-card:hover {
  @apply bg-gray-800;
}

/* Additional styles for skills and interests */
.skills-bar {
  @apply relative w-full h-4 bg-gray-700 rounded;
}

.skills-bar-fill {
  @apply absolute top-0 left-0 h-4 bg-blue-500 rounded;
}

.skills-list li {
  @apply mb-4;
}

.skills-list .flex {
  @apply justify-between mb-1;
}

/* Transition for skills bar */
.transition-width {
  transition-property: width;
  transition-timing-function: ease-in-out;
  transition-duration: 1000ms;
}

/* Menu styles for mobile */
.lg\:hidden .block a {
  @apply w-full px-4 py-2 border-b border-gray-700 text-gray-300 hover:text-white;
}

/* Styles for certifications */
.cert-item {
  @apply flex items-center bg-gray-800 rounded-lg shadow-lg p-5;
}

.cert-item h3 {
  @apply text-2xl font-bold mb-1 text-gray-100;
}

.cert-item p {
  @apply text-gray-400;
}

.cert-item img {
  @apply w-16 h-16 mr-4;
}

button {
  @apply mt-8 px-6 py-3 bg-blue-500 text-white font-bold rounded-lg shadow-lg hover:bg-blue-700 transition duration-300;
}

/* Styles for blog posts */
.blog-post {
  @apply bg-gray-800 rounded-lg shadow-lg overflow-hidden;
}

.blog-post img {
  @apply w-full h-64 object-cover;
}

.blog-post h3 {
  @apply text-2xl font-bold mb-2 text-gray-100;
}

.blog-post p {
  @apply text-gray-400;
}

.blog-post a {
  @apply no-underline text-gray-100 hover:underline;
}
